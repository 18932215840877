import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import Helper from "../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
  //   secSignUp: {},
};

export const shortsSlice = createSlice({
  name: "shorts",
  initialState,
  reducers: {},
});
export const { firstSignUp, secSignUp, getReasonList } = shortsSlice.actions;

export default shortsSlice.reducer;

export const shortsList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "listen_to_experts/shorts/list"
    ).then((response) => response.data);
    callback(result);
  };

export const CategoryList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "categories").then(
      (response) => response.data
    );
    callback(result);
  };

export const testActiveCb =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "testimonials/active").then(
      (response) => response.data
    );
    callback(result);
  };

export const activeFAQCb =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "faq/active_list").then(
      (response) => response.data
    );
    callback(result);
  };

export const listOfFreeCourses =
  (callback = () => {}) =>
  async () => {
    let courses = await Helper.getData(
      baseUrl + "courses/list_of_free_courses"
    ).then((res) => res.data);
    callback(courses);
  };
export const listOfPopularCourses =
  (callback = () => {}) =>
  async () => {
    let courses = await Helper.getData(
      baseUrl + "courses/list_of_popular_courses"
    ).then((res) => res.data);
    callback(courses);
  };

export const VideoTestimonialsList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "video_testimonials/active"
    ).then((response) => response.data);
    callback(result);
  };

export const subscriptionBannerList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_banner/active_subscription_banner_list"
    ).then((response) => response.data);
    callback(result);
  };

export const liveBannersList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "live_program_banner/active_live_program_banner_list"
    ).then((response) => response.data);
    callback(result);
  };
