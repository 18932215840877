import React, { useState, useEffect } from "react";
import "./Faq.css";

import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../../redux/common";
import { toast } from "react-toastify";
import { activeFAQCb } from "../../redux/shortsApi";

const Faq = () => {
  const dispatch = useDispatch();
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    dispatch(
      activeFAQCb((resp) => {
        if (resp.status) {
          setFaqData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, []);

  return (
    <div className="FAQpage">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/about">FAQ</Link>
                </p>
                <h2>FAQ</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FaqContent pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Accordion defaultActiveKey="0">
                {faqData?.map((item, i) => {
                  return (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{item?.question}</Accordion.Header>
                      <Accordion.Body>{item?.answer}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
